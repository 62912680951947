import React from "react";

import * as styles from "./rule.module.css";

export default function Rule() {

  return (
    <hr className={ styles.rule } />
  );
}
